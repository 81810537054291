import { EddaApi } from '../../../types/EnhancedPageContext';
import { DataSource, DataSourceOptions } from './DataSource';

/**
 * A common interface for a source of data that depends on the {@link EddaApi}, providing utilities for
 * fetching the data on the server and using it in a React component.
 */
export class ApiDataSource<T, P extends unknown[] = [], A = unknown> extends DataSource<
  T,
  [api: EddaApi, ...params: P],
  A
> {
  constructor(options: ApiDataSourceOptions<T, P, A>) {
    super({
      ...options,

      /**
       * We omit the `api` parameter for all of the options that accept except for the `getData` option.
       */

      getData: (api, ...params) => {
        if (!api) {
          console.error(
            'Attempted to fetch API data without the API defined. Most likely, this is because the data ' +
              'was attempted to be fetched inside a component during SSR, as opposed to in `getInitialProps`.',
          );
          return;
        }

        return options.getData(api, ...params);
      },

      argsEqualityChecker: ([api, ...params], args) => {
        if (typeof options.argsEqualityChecker === 'function') {
          return options.argsEqualityChecker(params, args);
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const paramsB = this.options.parseArgs!(args as A, api, ...params).slice(1);
        return DataSource.isArrayEqual(params, paramsB);
      },

      formatArgs(_api, ...params) {
        if (typeof options.formatArgs === 'function') {
          return options.formatArgs(...params);
        }
        return params as never;
      },

      parseArgs(args, api, ...params) {
        if (typeof options.parseArgs === 'function') {
          return [api, ...options.parseArgs(args, ...params)] as never;
        } else if (Array.isArray(args)) {
          return [api, ...(args as never)] as never;
        } else if (args) {
          return [api, args] as never;
        } else {
          return [api] as never;
        }
      },

      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      formatData:
        typeof options.formatData === 'function'
          ? (data, _api, ...params) => options.formatData!(data, ...params)
          : undefined,
      formatError:
        typeof options.formatError === 'function'
          ? (data, _api, ...params) => options.formatError!(data, ...params)
          : undefined,
      formatErrorMessage:
        typeof options.formatError === 'function'
          ? (data, _api, ...params) => options.formatErrorMessage!(data, ...params)
          : undefined,
      /* eslint-enable @typescript-eslint/no-non-null-assertion */
    });
  }
}

export interface ApiDataSourceOptions<T, P extends unknown[] = [], A = P>
  extends Omit<DataSourceOptions<T, P, A>, 'getData'>,
    Pick<DataSourceOptions<T, [api: EddaApi, ...params: P], A>, 'getData'> {}
