import { DependencyList, useMemo, useRef, useState } from 'react';

/**
 * A common interface for a source of data, providing utilities for
 * geting the data on the server and using it in a React component.
 */
export class DataSource<T, P extends unknown[] = [], A = unknown> {
  protected readonly options: DataSourceOptions<T, P, A>;

  constructor(options: DataSourceOptions<T, P, A>) {
    this.options = options;
  }

  /** Fetches the data and never throws an error. */
  async get(...params: P): Promise<DataResult<T, A>> {
    const result = {} as DataResult<T, A>;

    try {
      result.data = (await this.options.getData(...params)) || this.options.fallbackData;
    } catch (error) {
      if (typeof this.options.formatError === 'function' && error instanceof Error) {
        error = this.options.formatError(error, ...params);
      }
      if (error instanceof Error) {
        result.error = error;

        if (typeof this.options.formatErrorMessage === 'function') {
          result.errorMessage = this.options.formatErrorMessage(error.message, ...params);
        } else {
          result.errorMessage = error.message;
        }
      }
      if (!this.options.silent) {
        console.error(error);
      }
      result.data = this.options.fallbackData;
    }
    if (result.data != null && typeof this.options.formatData === 'function') {
      result.data = this.options.formatData(result.data, ...params) || this.options.fallbackData;
    }
    if (!this.options.excludeArgs) {
      if (typeof this.options.formatArgs === 'function') {
        result.args = this.options.formatArgs(...params);
      } else {
        result.args = params as never;
      }
    }
    return result;
  }

  /* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */
  /** @see {@link UseDataOptions} @see {@link DataState} */
  use(options?: UseDataOptions<T, A>, ...params: P): DataState<T, A> {
    const { initial, deps = [] } = options || {};

    /** Allows for "canceling" pending requests when dependencies change. */
    const getSymbolRef = useRef<symbol>();

    if (typeof window === 'undefined') {
      // no async (or fetch) during SSR
      return useMemo(() => ({ ...initial, loading: !initial } as DataState<T, A>), deps);
    } else if (this.options.excludeArgs) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [state, setState] = useState<DataState<T, A>>(
        Boolean(initial)
          ? ({ ...initial, loading: false } as DataState<T, A>)
          : { loading: true, data: this.options.fallbackData },
      );

      useMemo(() => {
        if (!state.loading) {
          return;
        }

        const getSymbol = (getSymbolRef.current = Symbol());

        this.get(...params).then(state => {
          if (getSymbolRef.current !== getSymbol) {
            return;
          }

          setState({ ...state, loading: false });
        });
      }, deps);

      return state;
    } else if (initial) {
      const [state, setState] = useState<DataState<T, A>>(
        useMemo(() => {
          if (this.areArgsEqual(params, initial.args)) {
            return { ...initial, loading: false };
          } else {
            return { ...initial, loading: true };
          }
        }, []),
      );

      const hydrateRef = useRef<symbol>();
      if (!this.areArgsEqual(params, state.args)) {
        hydrateRef.current = Symbol();
      }

      useMemo(() => {
        if (!hydrateRef.current) {
          return;
        }

        if (typeof this.options.formatArgs === 'function') {
          state.args = this.options.formatArgs(...params);
        } else {
          state.args = params as never;
        }

        const getSymbol = (getSymbolRef.current = Symbol());

        if (!state.loading) {
          setState({ ...state, loading: true });
        }

        this.get(...params).then(result => {
          if (getSymbolRef.current !== getSymbol) {
            return;
          }

          setState({ ...result, loading: false });
        });
      }, deps.concat(hydrateRef.current));

      return state;
    } else {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [state, setState] = useState<DataState<T, A>>({
        loading: true,
        data: this.options.fallbackData,
      });

      const hydrateRef = useRef<symbol>();
      if (!this.areArgsEqual(params, state.args)) {
        hydrateRef.current = Symbol();
      }

      useMemo(() => {
        if (typeof this.options.formatArgs === 'function') {
          state.args = this.options.formatArgs(...params);
        } else {
          state.args = params as never;
        }

        const getSymbol = (getSymbolRef.current = Symbol());

        if (!state.loading) {
          setState({ ...state, loading: true });
        }

        this.get(...params).then(result => {
          if (getSymbolRef.current !== getSymbol) {
            return;
          }

          setState({ ...result, loading: false });
        });
      }, deps.concat(hydrateRef.current));

      return state;
    }
  }

  /** @returns Whether two sets of arguments are deemed equal. */
  areArgsEqual(params: P, args: A | undefined) {
    if (!params) {
      params = [] as never;
    }
    if (typeof this.options.argsEqualityChecker === 'function') {
      return this.options.argsEqualityChecker(params, args);
    }
    if (typeof this.options.parseArgs === 'function') {
      const paramsB = this.options.parseArgs(args as A, ...params);
      return DataSource.isArrayEqual(params, paramsB);
    } else if (typeof this.options.formatArgs === 'function') {
      const argsB = this.options.formatArgs(...params);
      return DataSource.isEqual(args, argsB);
    } else {
      return DataSource.isEqual(params, args);
    }
  }

  /**
   * The default equality checker for arguments. It checks strict equality, but looks at elements
   * of arrays and keys of objects (and keys of objects that are elements of arrays.)
   *
   * (This may be replaced by `fast-deep-equal` in the future.)
   */
  static isEqual(a: unknown, b: unknown) {
    if (Array.isArray(a) && Array.isArray(b)) {
      return this.isArrayEqual(a, b);
    }
    if (a && b && typeof a === 'object' && typeof b === 'object') {
      return DataSource.areObjectElementsEqual(
        a as Record<string | number | symbol, unknown>,
        b as Record<string | number | symbol, unknown>,
      );
    } else {
      return a === b;
    }
  }

  protected static isArrayEqual(a: unknown[], b: unknown[]) {
    if (a.length !== b.length) {
      return false;
    }
    return a.every((elementA, index) => {
      if (b[index] === elementA) {
        return true;
      } else if (
        b[index] &&
        elementA &&
        typeof b[index] === 'object' &&
        typeof elementA === 'object'
      ) {
        return DataSource.areObjectElementsEqual(
          b[index] as Record<string | number | symbol, unknown>,
          elementA as Record<string | number | symbol, unknown>,
        );
      } else {
        return false;
      }
    });
  }

  protected static areObjectElementsEqual(
    a: Record<string | number | symbol, unknown>,
    b: Record<string | number | symbol, unknown>,
  ) {
    return (
      Object.keys(a).every(key => a[key] == b[key]) && Object.keys(b).every(key => a[key] == b[key])
    );
  }
}

export interface DataSourceOptions<T, P extends unknown[] = [], A = P> {
  /** Fetches the data. */
  getData(...params: P): T | undefined | Promise<T | undefined>;
  /** Data to return if get fails or if `get` or `formatData` returns undefined. */
  fallbackData: T;
  /** Whether not to log errors. */
  silent?: boolean;
  /** Whether to exclude args in the returned data  (must be true if the args cannot be
   * represented as JSON). This means data will never be updated when params change. */
  excludeArgs?: boolean;
  /** Formats non-null data. */
  formatData?(data: T, ...params: P): T | undefined;
  /** Formats thrown errors. */
  formatError?(error: Error, ...params: P): Error | undefined;
  /** Formats the message of thrown errors. */
  formatErrorMessage?(errorMessage: string, ...params: P): string | undefined;
  /** @returns Whether two sets of params are deemed equal. */
  argsEqualityChecker?(params: P, args: A | undefined): boolean;
  /** Formats the params to args. */
  formatArgs?(...params: P): A | undefined;
  /** Parses the args to params. */
  parseArgs?(args: A, ...params: P): P;
}

export interface UseDataOptions<T, A = unknown> {
  /** The initial data (e.g. geted from the server). */
  initial?: DataResult<T, A>;
  deps?: DependencyList;
}

export interface DataResult<T, A = unknown> {
  /** The retrieved data. It will always be defined with the correct type, even if an error has
   * been thrown, because a fallback has been defined. */
  data: T;
  error?: Error;
  errorMessage?: string;
  args?: A;
}

export interface DataState<T, A = unknown> extends DataResult<T, A> {
  loading: boolean;
}
